import React from "react";
import Location from "./Location";
import { Helmet } from "react-helmet";

const SouthB = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "South B Escorts",
        "description":
            "Discover premium escort services in South B, Nairobi. Connect with professionals near Capital Centre, Sanasana, Bellevue, and Hazina Estate for unforgettable experiences.",
        "provider": {
            "@type": "Organization",
            "name": "Kenya Hookups",
            "url": "https://www.kenyahookups.com/escorts/nairobi/south-b-escorts"
        },
        "areaServed": {
            "@type": "Place",
            "name": "South B",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "South B",
                "addressRegion": "Nairobi",
                "addressCountry": "KE"
            }
        },
        "hasPart": [
            {
                "@type": "Place",
                "name": "Capital Centre Mall",
                "description":
                    "A shopping hub featuring retail stores, restaurants, and entertainment options."
            },
            {
                "@type": "Place",
                "name": "Sanasana",
                "description":
                    "A well-known area offering a mix of residential and commercial establishments."
            },
            {
                "@type": "Place",
                "name": "Bellevue",
                "description":
                    "A vibrant neighborhood known for its accessibility and local businesses."
            },
            {
                "@type": "Place",
                "name": "Hazina Estate",
                "description":
                    "A serene residential estate perfect for quiet and private moments."
            },
            {
                "@type": "Place",
                "name": "Golden Gate Estate",
                "description":
                    "A well-organized residential estate offering a peaceful environment."
            },
            {
                "@type": "Place",
                "name": "Mater Hospital",
                "description":
                    "A renowned healthcare facility providing exceptional medical services."
            },
            {
                "@type": "Place",
                "name": "South B Shopping Centre",
                "description":
                    "A lively hub for groceries, eateries, and local shops."
            }
        ],
        "offers": {
            "@type": "Offer",
            "url": "https://www.kenyahookups.com/escorts/nairobi/south-b-escorts",
            "availability": "https://schema.org/InStock",
            "priceCurrency": "KES",
            "price": "Negotiable",
            "validFrom": new Date().toISOString()
        }
    };

    return (
        <Location
            title="South B Escorts | Exceptional Services in a Vibrant Neighborhood"
            description="Discover the best escorts in South B. Connect with professionals near Capital Centre, Sanasana, Bellevue, and Hazina Estate for unforgettable experiences."
            estate="south-b"
        >
            {/* Add Structured Data in Helmet */}
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>

            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                South B Escorts – Unforgettable Moments in a Thriving Community
            </h2>
            <p>
                South B, located off <b>Mombasa Road</b>, is one of Nairobi’s most
                dynamic neighborhoods. Known for its mix of residential estates,
                shopping centers, and accessibility, South B offers the perfect
                backdrop for unique and tailored experiences. Whether you’re near{" "}
                <b>Sanasana</b>, <b>Capital Centre</b>, or <b>Hazina Estate</b>, our
                South B escorts are here to make your time extraordinary.
            </p>
            <h3>Why Choose South B Escorts?</h3>
            <p>
                South B combines the convenience of urban living with the warmth of a
                close-knit community. From the vibrant streets of <b>Bellevue</b> to the
                serene surroundings of <b>Golden Gate Estate</b>, our escorts ensure
                every experience is seamless, memorable, and tailored to your
                preferences.
            </p>
            <ul>
                <li>
                    <b>Prime Locations:</b> Services near <b>Sanasana</b>, <b>South B
                        Shopping Centre</b>, and <b>Mater Hospital</b>.
                </li>
                <li>
                    <b>Vibrant and Dynamic:</b> Perfect for both casual and elegant
                    moments in South B’s bustling and serene settings.
                </li>
                <li>
                    <b>Personalized Experiences:</b> Tailored services designed to suit
                    your schedule and desires.
                </li>
            </ul>
            <h3>Explore South B’s Key Locations</h3>
            <p>
                South B is home to several iconic spots that highlight its charm and
                character:
            </p>
            <ul>
                <li>
                    <b>Capital Centre Mall:</b> A shopping hub featuring retail stores,
                    restaurants, and entertainment options.
                </li>
                <li>
                    <b>Sanasana:</b> A well-known area offering a mix of residential and
                    commercial establishments.
                </li>
                <li>
                    <b>Bellevue:</b> A vibrant neighborhood known for its accessibility
                    and local businesses.
                </li>
                <li>
                    <b>Hazina Estate:</b> A serene residential estate perfect for quiet
                    and private moments.
                </li>
                <li>
                    <b>Golden Gate Estate:</b> A well-organized residential estate
                    offering a peaceful environment.
                </li>
                <li>
                    <b>Mater Hospital:</b> A renowned healthcare facility providing
                    exceptional medical services.
                </li>
                <li>
                    <b>South B Shopping Centre:</b> A lively hub for groceries, eateries,
                    and local shops.
                </li>
            </ul>
            <p>
                Whether you’re visiting <b>Funzi Road</b> or exploring <b>Mombasa
                    Road</b>, South B offers the perfect setting for unforgettable moments
                with our escorts.
            </p>
            <h3>Exclusive Services in South B</h3>
            <p>
                Our South B escorts specialize in offering a variety of services to
                complement the area’s vibrant and serene settings:
            </p>
            <ul>
                <li>
                    Elegant companionship for casual outings or special occasions near{" "}
                    <b>Sanasana</b> or <b>Bellevue</b>.
                </li>
                <li>
                    Relaxing full-body massages to rejuvenate after shopping at <b>Capital
                        Centre</b> or exploring <b>Golden Gate Estate</b>.
                </li>
                <li>
                    Adventurous and playful moments in vibrant spots like <b>South B
                        Shopping Centre</b> or tranquil areas like <b>Hazina Estate</b>.
                </li>
                <li>
                    Customized services designed to create unforgettable memories in South
                    B.
                </li>
            </ul>
            <p>
                No matter where you are in South B, our escorts provide a seamless blend
                of charm, professionalism, and comfort.
            </p>
            <h3>Availability and Rates</h3>
            <p>
                Our South B escorts are available 24/7, providing services tailored to
                your needs. With transparent pricing and exceptional service, you can
                confidently plan your day or evening in South B.
            </p>
            <h3>Contact South B Escorts Today</h3>
            <p>
                Discover the finest escort services South B has to offer. Browse through
                our directory to find your perfect match near <b>Sanasana</b>, <b>Capital
                    Centre</b>, or <b>Bellevue</b>. Whether you’re visiting <b>Hazina
                        Estate</b>, <b>Golden Gate Estate</b>, or exploring <b>Funzi
                            Road</b>, our escorts are here to make your moments unforgettable.
            </p>
            <p>
                Don’t wait – South B’s most charming escorts are just a call away. Book
                now to enjoy unmatched services and memorable experiences in one of
                Nairobi’s most vibrant neighborhoods.
            </p>
        </Location>
    );
};

export default SouthB;