import React from "react";
import Location from "./Location";
import { Helmet } from "react-helmet";

const NairobiCBD = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Nairobi CBD Escorts",
        "description":
            "Discover premium escort services in Nairobi CBD. Connect with professionals near Tom Mboya, Moi Avenue, River Road, Luthuli, Gikomba, Kamukunji, and City Stadium for unforgettable experiences.",
        "provider": {
            "@type": "Organization",
            "name": "Kenya Hookups",
            "url": "https://www.kenyahookups.com/escorts/nairobi/nairobi-cbd-escorts"
        },
        "areaServed": {
            "@type": "Place",
            "name": "Nairobi CBD",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "Nairobi CBD",
                "addressRegion": "Nairobi",
                "addressCountry": "KE"
            }
        },
        "hasPart": [
            {
                "@type": "Place",
                "name": "Tom Mboya Street",
                "description":
                    "A bustling street in the heart of Nairobi, known for its commercial activity and nightlife."
            },
            {
                "@type": "Place",
                "name": "Moi Avenue",
                "description":
                    "A major road in Nairobi CBD lined with shopping centers, restaurants, and corporate offices."
            },
            {
                "@type": "Place",
                "name": "River Road",
                "description":
                    "A vibrant area known for its local businesses and energetic atmosphere."
            },
            {
                "@type": "Place",
                "name": "Luthuli Avenue",
                "description":
                    "A lively shopping street famous for its electronics and local businesses."
            },
            {
                "@type": "Place",
                "name": "Gikomba Market",
                "description":
                    "Nairobi’s largest open-air market known for second-hand clothes and unique items."
            },
            {
                "@type": "Place",
                "name": "Kamukunji",
                "description":
                    "A busy trading hub known for industrial tools and wholesale businesses."
            },
            {
                "@type": "Place",
                "name": "City Stadium",
                "description":
                    "A historic sports venue hosting football matches and public events."
            },
            {
                "@type": "Place",
                "name": "Muthurwa Market",
                "description":
                    "A bustling transport and trade hub in Nairobi CBD."
            }
        ],
        "offers": {
            "@type": "Offer",
            "url": "https://www.kenyahookups.com/escorts/nairobi/nairobi-cbd-escorts",
            "availability": "https://schema.org/InStock",
            "priceCurrency": "KES",
            "price": "Negotiable",
            "validFrom": new Date().toISOString()
        }
    };

    return (
        <Location
            title="Nairobi CBD Escorts | Vibrant Experiences in the Heart of the City"
            description="Discover premium escorts in Nairobi CBD. Connect with professionals near Tom Mboya, Moi Avenue, River Road, Luthuli, Gikomba, Kamukunji, and City Stadium for unforgettable moments."
            estate="nairobi-cbd"
        >
            {/* Add Structured Data in Helmet */}
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>

            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Nairobi CBD Escorts – Exceptional Experiences in the City Hub
            </h2>
            <p>
                Nairobi’s Central Business District (CBD) is the heartbeat of Kenya’s
                capital, known for its bustling streets, vibrant culture, and endless
                energy. Whether you're near <b>Tom Mboya Street</b>, <b>Moi Avenue</b>,
                or exploring the lively <b>River Road</b>, our Nairobi CBD escorts are
                here to provide unparalleled companionship and unforgettable
                experiences.
            </p>
            <h3>Why Choose Nairobi CBD Escorts?</h3>
            <p>
                Nairobi CBD offers a dynamic blend of historical charm and modern
                convenience, making it an exciting location for unique encounters. From
                the iconic <b>Luthuli Avenue</b> to the bustling <b>Kamukunji</b> and{" "}
                <b>Gikomba</b> markets, our escorts provide discreet and professional
                services that cater to your needs.
            </p>
            <ul>
                <li>
                    <b>Convenience:</b> Accessible locations near <b>Tom Mboya Street</b>,{" "}
                    <b>Moi Avenue</b>, and <b>City Stadium</b>.
                </li>
                <li>
                    <b>Vibrant Atmosphere:</b> Immerse yourself in the lively streets of{" "}
                    <b>River Road</b> and <b>Muthurwa</b>.
                </li>
                <li>
                    <b>Professionalism:</b> Our escorts are dedicated to delivering
                    experiences that blend discretion, charm, and luxury.
                </li>
            </ul>
            <h3>Exclusive Services in Nairobi CBD</h3>
            <p>
                Our Nairobi CBD escorts are skilled in offering services that complement
                the city’s energetic vibe:
            </p>
            <ul>
                <li>
                    Elegant companionship for evening outings or business events in the
                    CBD.
                </li>
                <li>
                    Relaxing massages to rejuvenate after a long day near{" "}
                    <b>Gikomba</b> or <b>Kamukunji</b>.
                </li>
                <li>
                    Adventurous and discreet meetups in iconic spots like{" "}
                    <b>Luthuli Avenue</b> or <b>River Road</b>.
                </li>
                <li>
                    Tailored services designed to make your time in <b>Muthurwa</b> or{" "}
                    <b>City Stadium</b> unforgettable.
                </li>
            </ul>
            <h3>Availability and Rates</h3>
            <p>
                Our Nairobi CBD escorts are available around the clock, offering
                services tailored to your schedule. With transparent pricing and
                top-notch service, you’re guaranteed an exceptional experience in the
                heart of the city.
            </p>
            <h3>Contact Nairobi CBD Escorts Today</h3>
            <p>
                Experience the best escort services Nairobi CBD has to offer. Browse our
                directory to find your perfect match near <b>Tom Mboya Street</b>,{" "}
                <b>Moi Avenue</b>, or <b>City Stadium</b>. Whether you're visiting{" "}
                <b>River Road</b>, <b>Gikomba</b>, or <b>Muthurwa</b>, our escorts are
                ready to make your time in Nairobi extraordinary.
            </p>
            <p>
                Don’t miss out – Nairobi CBD’s finest escorts are just a call away. Book
                now to enjoy unmatched experiences in Kenya’s vibrant city hub.
            </p>
        </Location>
    );
};

export default NairobiCBD;