import React, { useState } from 'react';
import { Steps, Button, Form, message } from 'antd';
import axios from 'axios';
import Location from './Location';
import ProfileDetails from './ProfileDetails';
import AvailabilityAndRates from './AvailabilityAndRates';
import Services from './Services';
import './RegistrationForm.css';

const { Step } = Steps;

const countries = ["Kenya", "Tanzania", "Uganda"];

const towns = {
  Kenya: ["Nairobi", "Mombasa", "Kisumu"],
};

const estates = {
  Nairobi: ["CBD", "Westlands", "Kilimani"],
  Mombasa: ["CBD", "Nyali", "Bamburi"],
  Kisumu: ["CBD", "Milimani", "Nyalenda"],
  Nakuru: ["CBD", "Milimani", "Lanet"],
};

export default function RegistrationForm() {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    username: "",
    country: "Kenya",
    city: "Nairobi",
    estate: "CBD",
    gender: "",
    age: 18,
    hairColor: "",
    hairLength: "",
    bustSize: "",
    height: "",
    weight: "",
    availability: { incall: false, outcall: false },
    about: "",
    education: "",
    sports: "",
    hobbies: "",
    nationality: "",
    sexualOrientation: "",
    occupation: "",
    languages: {},
    rates: { incall: {}, outcall: {} },
    services: {},
    currency: "KES",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const [category, item] = name.split(".");
      setFormData((prevData) => ({
        ...prevData,
        [category]: { ...prevData[category], [item]: checked },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "country") {
      setFormData((prevData) => ({
        ...prevData,
        city: towns[value][0],
        estate: estates[towns[value][0]][0],
      }));
    } else if (name === "city") {
      setFormData((prevData) => ({
        ...prevData,
        estate: estates[value][0],
      }));
    }
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      profile_picture: e[0].originalFileObj,
    });
  };

  const handleNestedChange = (e, category) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [category]: {
        ...formData[category],
        [name]: value,
      },
    });
  };

  const handleAvailabilityChange = (e) => {
    const { name, checked } = e.target;
    const [type, period] = name.split('.');
    setFormData({
      ...formData,
      availability: {
        ...formData.availability,
        [type]: {
          ...formData.availability[type],
          [period]: checked,
        },
      },
    });
  };

  const handleSubmit = async (values) => {
    const data = new FormData();
    for (const key in formData) {
      if (typeof formData[key] === 'object' && !Array.isArray(formData[key])) {
        for (const nestedKey in formData[key]) {
          data.append(`${key}[${nestedKey}]`, formData[key][nestedKey]);
        }
      } else {
        data.append(key, formData[key]);
      }
    }
    if (formData.profile_picture) {
      data.append('profile_picture', formData.profile_picture);
    }

    try {
      const response = await axios.post('/auth/register', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201) {
        message.success('Model registered successfully!');
      }
    } catch (error) {
      console.error('Error registering model:', error);
      message.error('Error registering model. Please try again.');
    }
  };

  const steps = [
    { title: "Profile Details", content: <ProfileDetails formData={formData} handleChange={handleChange} handleFileChange={handleFileChange} /> },
    { title: "Location", content: <Location formData={formData} handleChange={handleChange} towns={towns} estates={estates} countries={countries} /> },
    { title: "Availability & Rates", content: <AvailabilityAndRates formData={formData} handleChange={handleChange} handleNestedChange={handleNestedChange} handleAvailabilityChange={handleAvailabilityChange} /> },
    { title: "Services", content: <Services formData={formData} handleChange={handleNestedChange} /> },
  ];

  const next = async () => {
    try {
      await form.validateFields();
      setCurrent(current + 1);
    } catch (errorInfo) {
      console.log('Validation Failed:', errorInfo);
    }
  };

  const prev = () => setCurrent(current - 1);

  return (
    <Form form={form} onFinish={handleSubmit} className="form-container">
      <h1>Independent Escort Registration</h1>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current > 0 && (
          <Button style={{ margin: "5px 3px", maxWidth: 400 }} type="primary" onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button style={{ margin: "5px 3px", maxWidth: 400 }} type="primary" htmlType="submit">
            Submit
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button style={{ margin: "5px 3px", maxWidth: 400 }} type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
      </div>
    </Form>
  );
}