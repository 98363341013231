import React, { useState } from "react";
import { Form, Input, Select, Upload, Button } from "antd";
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const ProfileDetails = ({ formData, handleChange, handleFileChange }) => {
  const [fileList, setFileList] = useState([]);

  const ageOptions = [];
  for (let i = 18; i <= 100; i++) {
    ageOptions.push(<Select.Option key={i} value={i}>{i}</Select.Option>);
  }

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
    handleFileChange(fileList);
  };

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      style={{ maxWidth: 800, margin: '0 auto' }}
    >
      <Form.Item label="Name:" required>
        <Input
          name="name"
          type="text"
          value={formData.name}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item label="Phone:" required>
        <Input
          name="phone"
          type="text"
          value={formData.phone}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item label="Gender">
        <Select
          name="gender"
          value={formData.gender}
          onChange={(value) => handleChange({ target: { name: "gender", value } })}
        >
          <Select.Option value="female">Female escort, call girl</Select.Option>
          <Select.Option value="male">Male/ Call Boy</Select.Option>
          <Select.Option value="couple">Couple</Select.Option>
          <Select.Option value="gay">Gay</Select.Option>
          <Select.Option value="trans">Transsexual/ Shemale</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Sexual Orientation">
        <Select
          name="sexual_orientation"
          value={formData.sexual_orientation}
          onChange={(value) => handleChange({ target: { name: "sexual_orientation", value } })}
        >
          <Select.Option value="straight">Straight</Select.Option>
          <Select.Option value="gay">Gay</Select.Option>
          <Select.Option value="bisexual">Bisexual</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Age:" required>
        <Select
          name="age"
          value={formData.age}
          onChange={(value) => handleChange({ target: { name: 'age', value } })}
        >
          {ageOptions}
        </Select>
      </Form.Item>

      <Form.Item label="Nationality:">
        <Select
          name="nationality"
          value={formData.ethnicity}
          onChange={(value) => handleChange({ target: { name: "nationality", value } })}
        >
          <Select.Option value="kenyan">Kenyan</Select.Option>
          <Select.Option value="ugandan">Ugandan</Select.Option>
          <Select.Option value="somali">Somali</Select.Option>
          <Select.Option value="indian">Indian</Select.Option>
          <Select.Option value="tanzanian">Tanzanian</Select.Option>
          <Select.Option value="ethiopian">Ethiopian</Select.Option>
          <Select.Option value="ethiopian">Arab</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="About you:" required>
        <TextArea
          name="about"
          value={formData.about}
          onChange={handleChange}
          rows={4}
        />
      </Form.Item>

      <Form.Item label="Profile Pictures:">
        <Upload
          name="profile_picture"
          listType="picture"
          multiple
          fileList={fileList}
          onChange={handleUploadChange}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item>
    </Form>
  );
};

export default ProfileDetails;