// Estates.js
import React from 'react';
import { Button } from 'antd';
import { Collapse } from 'antd';

const Estates = ({ handleClick }) => {
  const estates = [
    "Westlands",
    "Kilimani",
    "Lavington",
    "Kileleshwa",
    "Gigiri",
    "Muthaiga",
    "Langata",
    "Embakasi",
    "Nairobi West",
    "Ruaka",
    "Karen",
    "Runda",
    "South B",
    "South C",
    "Parklands",
    "Mirema",
    "Donholm",
    "Buruburu",
    "Kahawa Sukari",
    "Kasarani"
  ];

  const roads = [
    "Mombasa Road",
    "Thika Road",
    "Ngong Road",
    "Langata Road",
    "Kiambu Road",
    "Outering Road",
    "Southern Bypass",
    "Eastern Bypass",
    "Northern Bypass",
    "Jogoo Road",
    "Uhuru Highway",
    "Waiyaki Way"
  ];

  const onClick = (key) => { handleClick(key) };

  const locItems = [
    {
      key: '1',
      label: 'Nairobi Estates',
      children: (
        <div className="tags-container">
          {estates.map((estate) => (
            <Button key={estate} onClick={() => onClick(estate)} className="tag">
              {estate}
            </Button>
          ))}
        </div>
      ),
    },
    {
      key: '2',
      label: 'Major Roads',
      children: (
        <div className="tags-container">
          {roads.map((estate) => (
            <Button key={estate} onClick={() => onClick(estate)} className="tag">
              {estate}
            </Button>
          ))}
        </div>
      ),
    },
  ];

  return (
    <Collapse items={locItems} defaultActiveKey={['1']} style={{ backgroundColor: "#F4F6F8", minWidth: "350px" }} />
  );
};

export default Estates;