import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from './Sidebar';
import ListingCard from './ListingCard';
import './HomePage.css';
import axios from 'axios';
import { API_URL } from '../constants';
import ProfileCard from './ProfileCard';
import LocationsSection from '../shared/LocationsSection';

const HomePage = () => {
  const [models, setModels] = useState([]);

  const url = API_URL + "users";
  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.get(url);
        const data = response.data;
        const listingsWithImages = data.map((listing, index) => ({ ...listing }));
        setModels(listingsWithImages);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, [url]);

  const handleLocationSelect = (data) => {
    const listingsWithImages = data.map((listing, index) => ({ ...listing }));
    setModels(listingsWithImages);
  };

  return (
    <div className="home-page">
      {/* Add Helmet for meta tags */}
      <Helmet>
        <title>Hot Kenyan Escorts | Steamy Hookups in Nairobi</title>
        <link rel="canonical" href="https://www.kenyahookups.com/" />
        <meta
          name="description"
          content="Explore a selection of top Kenyan escorts and steamy hookups in Nairobi. Browse profiles of models and discover your ideal companion."
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Hot Kenyan Escorts | Steamy Hookups in Nairobi" />
        <meta property="og:description" content="Explore a selection of top Kenyan escorts and steamy hookups in Nairobi. Browse profiles of models and discover your ideal companion." />
        <meta property="og:image" content="URL_to_image.jpg" />
        <meta property="og:url" content="https://www.kenyahookups.com/" />
      </Helmet>

      {/* Add H1 and H2 */}
      <header className="home-page-header">
        <h1 className="home-page-title"> Escorts in Kenya</h1>
        <h2 className="home-page-description">
          Explore profiles of the hottest escorts in Kenya. Discover your ideal companion in Nairobi or other cities in Kenya today.
        </h2>
      </header>

      {/* Sidebar */}
      <Sidebar onLocationSelect={handleLocationSelect} />

      {/* Listings */}
      <div className="listings-grid">
        {models.map((listing, index) => (
          <ProfileCard key={index} {...listing} />
        ))}
      </div>

      {/* SEO Content */}
      <div className="seo-text">
        {models.length > 0 ? (
          <>
            <h2>Welcome to Kenya's Premier Escorts and Call Girls Platform</h2>
            <p>
              Are you an escort in Kenya? Create your profile today to gain visibility and connect with clients across the country. Upgrade to a VIP listing to enjoy enhanced exposure, guaranteed priority placement, and a premium tag on your profile.
            </p>
            {/* Other SEO content */}
          </>
        ) : (
          <div className="seo-placeholder">Loading content...</div>
        )}
      </div>

      {/* Footer */}
      <LocationsSection />
    </div>
  );
};

export default HomePage;