import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { API_URL } from '../constants';
import ReactMarkdown from 'react-markdown';

import './BlogPost.css'; // Import CSS file for styling
import LocationsSection from '../shared/LocationsSection';

const BlogPost = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false); // Track image load status

  const url = `${API_URL}blogs/${slug}`;

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(url);
        setBlog(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]);

  if (loading) return <SkeletonLoader />;
  if (error) return <p>Error loading blog: {error.message}</p>;
  if (!blog) return <p>Blog not found.</p>;

  return (
    <div className="container">
      <Helmet>
        <title>{blog.title} | My Blog</title>
        <meta name="description" content={blog.content.substring(0, 150)} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.content.substring(0, 150)} />
        <meta property="og:image" content={blog.image_url || 'default-image-url.jpg'} />
        <meta property="og:type" content="article" />
      </Helmet>
      <header className="head">
        <h1>{blog.title}</h1>
        <p className="meta">
          By {blog.author_name} on {new Date(blog.published_date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}
        </p>
      </header>
      <main>
        {/* Image with Placeholder */}
        <div className="image-container">
          {!imageLoaded && <div className="image-placeholder">Loading Image...</div>}
          <img
            src={blog.image_url}
            alt={blog.title}
            className={`image ${imageLoaded ? 'loaded' : 'loading'}`}
            onLoad={() => setImageLoaded(true)}
          />
        </div>
        <ReactMarkdown className="content">{blog.content}</ReactMarkdown>
      </main>

      {imageLoaded && <LocationsSection />}
    </div>
  );
};

// Skeleton Loader Component
const SkeletonLoader = () => {
  return (
    <div className="container">
      <header className="head">
        <div className="skeleton skeleton-title"></div>
        <div className="skeleton skeleton-meta"></div>
      </header>
      <main>
        <div className="skeleton skeleton-image"></div>
        <div className="skeleton skeleton-content"></div>
        <div className="skeleton skeleton-content"></div>
        <div className="skeleton skeleton-content"></div>
      </main>
    </div>
  );
};

export default BlogPost;