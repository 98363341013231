import React, { useEffect } from 'react'
import { Row, Col, Button, Form, Input } from 'antd'
import { UserOutlined, MailOutlined } from '@ant-design/icons'
import emailjs from '@emailjs/browser'
import ReactGA from "react-ga4"
import './ContactUs.css';

const { TextArea } = Input

const ContactUs = () => {
    const formName = 'contact'

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/contact-us" });
    }, [])

    const handleSubmit = (values) => {



        emailjs.send(
            'service_wnfijmt', // Replace with your EmailJS Service ID
            'template_vf3wzg3', // Your provided EmailJS Template ID
            {
                name: values.name,
                email: values.email,
                message: values.message,
            },
            'uzIncFSXsyX_pWRnY' // Replace with your EmailJS Public Key
        )
            .then(() => {
                showSuccess();
            })
            .catch((error) => {
                showError(error);
            });
    }

    const showSuccess = () => {
        alert('Your message has been sent successfully!')
    }

    const showError = (error) => {
        alert('There was an error sending your message. Please try again.')
        console.error('Error:', error)
    }

    return (
        <Row justify="space-around">
            <Col xs={22} sm={18} md={16} lg={8} className="form-container">
                <Form name="cf" method="post" onFinish={handleSubmit} layout="vertical">
                    <Form.Item label="Name" className="form-item" rules={[{ required: true, message: 'Please enter your name.' }]} name="name">
                        <Input placeholder="Name" prefix={<UserOutlined className="site-form-item-icon" />} />
                    </Form.Item>

                    <Form.Item label="Email" name="email" className="form-item" rules={[{ required: true, type: 'email', message: 'Please enter your email.' }]}>
                        <Input placeholder="Your Email" prefix={<MailOutlined className="site-form-item-icon" />} />
                    </Form.Item>

                    <Form.Item label="Message" name="message" className="form-item" rules={[{ required: true, message: 'Please enter your message.' }]}>
                        <TextArea placeholder="Your Message" rows={5} />
                    </Form.Item>

                    <Form.Item>
                        <Button className="send-button" htmlType="submit"> Send </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

export default ContactUs