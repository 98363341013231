//import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { SpeedInsights } from "@vercel/speed-insights/react"
import RegistrationForm from "./registration/RegistrationForm";
import './App.css'
import ProfilePage from "./profilepage/ProfilePage";
import HomePage from "./home/HomePage";
import NavBar from "./navbar/NavBar";
import LoginRegister from "./registration/LoginRegisterPage";
import ContactUs from "./contactus/ContactUs";
import Location from "./pages/Location";
import ReactGA from "react-ga4";
import Blog from "./blog/Blog";
import BlogPost from "./blog/BlogPost";
import NotFoundPage from "./pages/NotFoundPage";
import Embakasi from "./pages/Embakasi";
import Kilimani from "./pages/Kilimani";
import Ruiru from "./pages/Ruiru";
import Roysambu from "./pages/Roysambu";
import NairobiCBD from "./pages/NairobiCBD";
import Kasarani from "./pages/Kasarani";
import Donholm from "./pages/Donholm";
import Kileleshwa from "./pages/Kileleshwa";
import Langata from "./pages/Langata";
import Umoja from "./pages/Umoja";
import Ruaka from "./pages/Ruaka";
import Juja from "./pages/Juja";
import Kikuyu from "./pages/Kikuyu";
import Lavington from "./pages/Lavington";
import SouthB from "./pages/SouthB";
import SouthC from "./pages/SouthC";
import Rongai from "./pages/Rongai";
import Madaraka from "./pages/Madaraka";
import Utawala from "./pages/Utawala";
import NairobiWest from "./pages/NairobiWest";
import Westlands from "./pages/Westlands";
import GardenEstate from "./pages/GardenEstate";
import GardenCity from "./pages/GardenCity";

const AnalyticsTracker = () => {
  //const location = useLocation(); // useLocation works inside Router context

  useEffect(() => {
    ReactGA.initialize("G-GNYCD40J7F", { debug: true });

    // Track page views on route changes
    ReactGA.send({ hitType: "pageview" });
  }, []);

  return null; // No UI; just tracking
};

function App() {


  return (
    <div className="App">
      <Router>
        <AnalyticsTracker /> {/* Track page views */}
        <NavBar />
        <main className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/escorts/nairobi/donholm-escorts" element={<Donholm />} />
            <Route path="/escorts/nairobi/embakasi-escorts" element={<Embakasi />} />
            <Route path="/escorts/nairobi/juja-escorts" element={<Juja />} />
            <Route path="/escorts/nairobi/kasarani-escorts" element={<Kasarani />} />
            <Route path="/escorts/nairobi/kikuyu-escorts" element={<Kikuyu />} />
            <Route path="/escorts/nairobi/kilimani-escorts" element={<Kilimani />} />
            <Route path="/escorts/nairobi/kileleshwa-escorts" element={<Kileleshwa />} />
            <Route path="/escorts/nairobi/langata-escorts" element={<Langata />} />
            <Route path="/escorts/nairobi/lavington-escorts" element={<Lavington />} />
            <Route path="/escorts/nairobi/madaraka-escorts" element={<Madaraka />} />
            <Route path="/escorts/nairobi/nairobi-cbd-escorts" element={<NairobiCBD />} />
            <Route path="/escorts/nairobi/nairobi-west-escorts" element={<NairobiWest />} />
            <Route path="/escorts/nairobi/rongai-escorts" element={<Rongai />} />
            <Route path="/escorts/nairobi/roysambu-escorts" element={<Roysambu />} />
            <Route path="/escorts/nairobi/ruaka-escorts" element={<Ruaka />} />
            <Route path="/escorts/nairobi/ruiru-escorts" element={<Ruiru />} />
            <Route path="/escorts/nairobi/south-b-escorts" element={<SouthB />} />
            <Route path="/escorts/nairobi/south-c-escorts" element={<SouthC />} />
            <Route path="/escorts/nairobi/umoja-escorts" element={<Umoja />} />
            <Route path="/escorts/nairobi/utawala-escorts" element={<Utawala />} />
            <Route path="/escorts/nairobi/westlands-escorts" element={<Westlands />} />
            <Route path="/escorts/nairobi/garden-estate-escorts" element={<GardenEstate />} />
            <Route path="/escorts/nairobi/garden-city-escorts" element={<GardenCity />} />

            {/* <Route path="/escorts/nairobi/:estate" element={<Location />} /> */}
            <Route path="/registration" element={<RegistrationForm />} />
            <Route path="/escort/:name" element={<ProfilePage />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogs/:slug" element={<BlogPost />} />
            <Route path="/login" element={<LoginRegister />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
      </Router>
      {process.env.NODE_ENV === "development" && <SpeedInsights />}
    </div>
  );
}

export default App;