import React, { useEffect, useState } from 'react';
import { Tabs, Form, Input, Button, Checkbox, Row, Col } from 'antd';
import './LoginRegisterPage.css'; // Import the custom CSS
import ReactGA from "react-ga4";
const { TabPane } = Tabs;

const LoginForm = ({ handleLogin }) => (
  <Form name="login" onFinish={handleLogin}>
    <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]} >
      <Input placeholder="Email address" />
    </Form.Item>
    <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
      <Input.Password placeholder="Password" />
    </Form.Item>
    <Form.Item name="remember" valuePropName="checked">
      <Checkbox>Remember me</Checkbox>
    </Form.Item>
    <Form.Item>
      <a className="login-form-forgot" href="/"> Forgot your password?</a>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit" block> Log in</Button>
    </Form.Item>
  </Form>
);

const SignupForm = ({ handleSignup }) => (
  <Form name="signup" onFinish={handleSignup}>
    <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]} >
      <Input placeholder="Email address" />
    </Form.Item>
    <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
      <Input.Password placeholder="Password" />
    </Form.Item>
    <Form.Item name="repeatPassword" rules={[{ required: true, message: 'Please repeat your password!' }]} >
      <Input.Password placeholder="Repeat password" />
    </Form.Item>
    <Form.Item name="remember" valuePropName="checked">
      <Checkbox>Remember me</Checkbox>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit" block> Sign up </Button>
    </Form.Item>
  </Form>
);

const LoginRegisterPage = () => {
  const [activeKey, setActiveKey] = useState('1');

  const handleLogin = (values) => {  };

  const handleSignup = (values) => {  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/contact-us" });
}, [])

  return (
    <Row className="login-register-container">
      <Col xs={24} lg={12}>
        <div className="form-container">
          <Tabs activeKey={activeKey} onChange={setActiveKey} centered className="custom-tabs">
            <TabPane tab="Login" key="1"> <LoginForm handleLogin={handleLogin} /> </TabPane>
            <TabPane tab="Sign up" key="2"> <SignupForm handleSignup={handleSignup} /> </TabPane>
          </Tabs>
        </div>
      </Col>
    </Row>
  );
};

export default LoginRegisterPage;