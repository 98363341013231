import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import DetailsNew from './DetailsNew';
import { Helmet } from "react-helmet";
import { Divider } from 'antd';
import ListingCard from '../home/ListingCard';
import './ProfilePage.css';
import { API_URL } from '../constants';
import ReactGA from "react-ga4";
import CallIcon from '@mui/icons-material/Call';
import LocationsSection from '../shared/LocationsSection';


const Services = ({ services }) => {
  return (
    <section className="services">
      <h2 className="sections-heading">Services:</h2>
      <div className="tags-container">
        {services && services.length > 0 ? (
          services.map((service, index) => (
            <div key={index} className="service-tag">
              {service.service_name}
            </div>
          ))
        ) : (
          <p>No services available.</p>
        )}
      </div>
    </section>
  );
};

const ProfilePage = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [img, setImg] = useState("");
  const { name } = useParams();
  const location = useLocation();

  const url = API_URL + "profile/" + name;

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(url);
        const data = response.data.profile;  // Correctly access the profile data
        setProfileData(data);
        // Safely access image URLs, checking if images array exists
        if (data.images && data.images.length > 0 && data.images[0]?.image_url) {
          setImg(data.images[0].image_url);  // Use the first image in the array
        } else {
          // Fallback image if no image is found
          setImg("https://media-v2.tryst.a4cdn.org/profiles/f41dee16-c477-4090-96f9-961ac4d36428/photos/2867331b-2d9a-4341-855b-813b8f36e119/small.avif");
        }

        setLoading(false);  // Stop loading when data is fetched
      } catch (err) {
        setError(err);  // Set error state if fetching fails
        setLoading(false);  // Stop loading on error
        console.error("Error fetching profile:", err);
      }
    };

    fetchProfile();
  }, [name, url]);  // Dependencies to refetch on `name` or `url` change

  useEffect(() => {
    if (profileData) {
      ReactGA.send({ hitType: "pageview", page: `/model-profile/${profileData.id}`, title: `Model Profile ${profileData.name}` });
    }
  }, [profileData]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading profile: {error.message}</p>;

  return (
    <div className="profile-container">
      <Helmet>
        <title>{`${profileData.name}, ${profileData.age} | Escorts in ${profileData.estate}, ${profileData.city}`}</title>
        <meta
          name="description"
          content={`Meet ${profileData.name}, a top-rated ${profileData.age}-year-old escort in ${profileData.city}. Book a session today for an unforgettable experience.`}
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://www.kenyahookups.com/escort/${profileData.name}`} />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            "name": profileData.name,
            "image": profileData.imageUrl || img,
            "description": profileData.about || `Discover ${profileData.name}, a premium escort in ${profileData.city}.`,
            "jobTitle": "Escort",
            "gender": profileData.gender || "Female",
            "nationality": profileData.nationality || "Kenyan",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": profileData.city || "Nairobi",
              "addressRegion": profileData.estate || "Westlands",
              "addressCountry": "KE"
            },
            "url": `https://www.kenyahookups.com/escort/${profileData.name}`
          })}
        </script>
      </Helmet>

      <div className="outer-div">
        <img alt={`Photo of ${profileData.name} - Escort in ${profileData.city}`} className="foreground-image" src={profileData.imageUrl || img} loading="lazy" />
      </div>


      <button className="call-button" onClick={() => window.location.href = `tel:${profileData.phone}`}>
        <CallIcon style={{ marginRight: "8px", fontSize: "24px" }} />
        <span data-nosnippet>Call {profileData.phone}</span>
      </button>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "fit-content",
        margin: "0 auto",
      }}>
        <h2 style={{ margin: "10px 10px 10px 10px", color: "#222", fontSize: "36px" }}>
          {profileData.name}
        </h2>
        <Divider
          style={{
            backgroundColor: "#cb2845",
            height: "4px",
            width: "fit-content",
            margin: "0 auto",
          }}
        />
      </div>

      <h3
        style={{
          margin: "10px 10px 10px 20px",
          color: "#cb2845",
          fontSize: "28px",
          textAlign: "center",
        }}
      >
        {profileData.estates}
      </h3>

      <div className="about-container">
        <p className="about-paragraph"> {profileData.about}</p>
      </div>



      <div className="profile-details">
        <DetailsNew {...profileData} />

        <Services services={profileData.services} />
        <div>
          {/* <h2 className="sections-heading">Similar Profiles</h2> */}
          {/* <div className="similar-profiles" style={{ marginTop: "16px", marginLeft: "20px", padding: "16px", overflowX: "auto", whiteSpace: "nowrap" }}>
            {profileData.similarProfiles && profileData.similarProfiles.length > 0 ? (
              profileData.similarProfiles.map((profile) => (
                <ListingCard key={profile.id} {...profile} />
              ))
            ) : (
              <p>No similar profiles available.</p>
            )}
          </div> */}
        </div>

      </div>

      {/* New Section: Nairobi Estates */}
      <LocationsSection />
    </div>
  );
};

export default ProfilePage;