import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./Location.css";
import axios from "axios";
import { API_URL } from "../constants";
import { Breadcrumb, Button, Modal } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ProfileCard from "../home/ProfileCard";
import LocationsSection from "../shared/LocationsSection";

const Location = ({ title, description, estate, children, structuredData }) => {
  const [models, setModels] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const fetchListings = async () => {
    try {
      const url = `${API_URL}users/location`;
      const response = await axios.get(url, {
        params: { estate },
      });
      const data = response.data;



      if (!data || data.length === 0) {
        setModels([]); // No data found
        console.warn(`No listings found for ${estate}.`);
      } else {
        setModels(data.map((listing) => ({ ...listing })));
      }
    } catch (error) {
      console.error("Error fetching listings:", error);
      setModels([]); // Handle error by setting an empty array
    }
  };

  useEffect(() => {
    fetchListings();
  }, [estate]);

  const handleOk = () => setIsModalOpen(false);

  const handleCancel = () => setIsModalOpen(false);

  const navigate = useNavigate();

  const handleClick = (selectedEstate) => {
    navigate(`/escorts/nairobi/${selectedEstate}`);
    setIsModalOpen(false);
  };

  return (
    <div className="home-page">
      {/* Add Helmet for dynamic meta tags */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        {structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>

      <div className="location-header">
        <Breadcrumb
          items={[
            {
              href: "/",
              title: <HomeOutlined />,
            },
            {
              title: `Escorts ${estate}`,
            },
          ]}
          style={{ marginTop: "20px", marginLeft: "20px" }}
        />
        <Button onClick={showModal}>CHANGE LOCATION</Button>
      </div>

      <Modal title="Select a location" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer="">
        <LocationsSection handleClick={handleClick} />
      </Modal>

      {/* Listings */}
      <div className="listings-grid">
        {models.length > 0 ? (
          models.map((listing, index) => <ProfileCard key={index} {...listing} />)
        ) : (
          <div className="no-listings">
            <h3>No models found in {estate}.</h3>
            <p>We are working hard to add more listings in this area. Please check back later!</p>
          </div>
        )}
      </div>

      {/* Additional Content */}
      <div className="additional-content" style={{ padding: "40px 20px", backgroundColor: "white" }}>
        {children}
      </div>

      <LocationsSection />
    </div>
  );
};

export default Location;