import React from "react";
import Location from "./Location";
import { Helmet } from "react-helmet";

const SouthC = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "South C Escorts",
        "description":
            "Discover premium escort services in South C, Nairobi. Connect with professionals near Nextgen Mall, Bellevue, Mugoya Estate, and Akiba Estate for unforgettable experiences.",
        "provider": {
            "@type": "Organization",
            "name": "Kenya Hookups",
            "url": "https://www.kenyahookups.com/escorts/nairobi/south-c-escorts"
        },
        "areaServed": {
            "@type": "Place",
            "name": "South C",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "South C",
                "addressRegion": "Nairobi",
                "addressCountry": "KE"
            }
        },
        "hasPart": [
            {
                "@type": "Place",
                "name": "Nextgen Mall",
                "description":
                    "A modern shopping mall with retail stores, restaurants, and entertainment options."
            },
            {
                "@type": "Place",
                "name": "Bellevue South C",
                "description":
                    "A peaceful residential area perfect for relaxing and private moments."
            },
            {
                "@type": "Place",
                "name": "Mugoya Estate",
                "description": "Known for its quiet and family-friendly environment."
            },
            {
                "@type": "Place",
                "name": "Akiba Estate",
                "description": "A well-organized estate with modern housing options."
            },
            {
                "@type": "Place",
                "name": "South C Shopping Centre",
                "description": "A lively hub for groceries, eateries, and local shops."
            },
            {
                "@type": "Place",
                "name": "Red Cross Headquarters",
                "description":
                    "A prominent landmark and institution within South C."
            },
            {
                "@type": "Place",
                "name": "Wilson Airport",
                "description":
                    "Located nearby, offering convenient access to domestic and regional flights."
            }
        ],
        "offers": {
            "@type": "Offer",
            "url": "https://www.kenyahookups.com/escorts/nairobi/south-c-escorts",
            "availability": "https://schema.org/InStock",
            "priceCurrency": "KES",
            "price": "Negotiable",
            "validFrom": new Date().toISOString()
        }
    };

    return (
        <Location
            title="South C Escorts | Premium Services in Nairobi’s Vibrant Neighborhood"
            description="Discover the best escorts in South C. Connect with professionals near Nextgen Mall, Bellevue, Mugoya Estate, and Akiba Estate for unforgettable experiences."
            estate="south-c"
        >
            {/* Add Structured Data in Helmet */}
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>

            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                South C Escorts – Memorable Moments in a Vibrant Setting
            </h2>
            <p>
                South C, one of Nairobi’s most vibrant neighborhoods, combines
                residential charm with modern convenience. Known for its serene estates,
                bustling shopping centers, and proximity to key landmarks like{" "}
                <b>Nextgen Mall</b> and <b>Wilson Airport</b>, South C is the perfect
                place for unique and tailored experiences. Whether you’re in{" "}
                <b>Bellevue</b>, exploring <b>Mugoya Estate</b>, or near{" "}
                <b>Akiba Estate</b>, our South C escorts are here to make your time
                unforgettable.
            </p>
            <h3>Why Choose South C Escorts?</h3>
            <p>
                South C offers a blend of tranquility and activity, making it ideal for
                discreet and personalized encounters. From the lively atmosphere of{" "}
                <b>South C Shopping Centre</b> to the serene environment of{" "}
                <b>Bellevue South C</b>, our escorts ensure every experience is seamless
                and memorable.
            </p>
            <ul>
                <li>
                    <b>Prime Locations:</b> Services near <b>Nextgen Mall</b>,{" "}
                    <b>Akiba Estate</b>, and <b>Red Cross Headquarters</b>.
                </li>
                <li>
                    <b>Dynamic and Relaxing:</b> Perfect for both casual outings and
                    elegant encounters in South C’s diverse settings.
                </li>
                <li>
                    <b>Personalized Experiences:</b> Tailored services to match your
                    preferences and schedule.
                </li>
            </ul>
            <h3>Explore South C’s Key Locations</h3>
            <p>
                South C is home to several iconic spots that highlight its charm and
                character:
            </p>
            <ul>
                <li>
                    <b>Nextgen Mall:</b> A modern shopping mall with retail stores,
                    restaurants, and entertainment options.
                </li>
                <li>
                    <b>Bellevue South C:</b> A peaceful residential area perfect for
                    relaxing and private moments.
                </li>
                <li>
                    <b>Mugoya Estate:</b> Known for its quiet and family-friendly
                    environment.
                </li>
                <li>
                    <b>Akiba Estate:</b> A well-organized estate with modern housing
                    options.
                </li>
                <li>
                    <b>South C Shopping Centre:</b> A lively hub for groceries, eateries,
                    and local shops.
                </li>
                <li>
                    <b>Red Cross Headquarters:</b> A prominent landmark and institution
                    within South C.
                </li>
                <li>
                    <b>Wilson Airport:</b> Located nearby, offering convenient access to
                    domestic and regional flights.
                </li>
            </ul>
            <p>
                Whether you’re exploring <b>Ole Shapara Avenue</b> or enjoying a meal at{" "}
                <b>Boma Hotel</b>, South C offers the perfect environment for tailored
                experiences with our escorts.
            </p>
            <h3>Exclusive Services in South C</h3>
            <p>
                Our South C escorts specialize in providing services that cater to the
                neighborhood’s vibrant and serene atmosphere:
            </p>
            <ul>
                <li>
                    Elegant companionship for casual outings or special occasions near{" "}
                    <b>Nextgen Mall</b> or <b>Bellevue South C</b>.
                </li>
                <li>
                    Relaxing full-body massages to rejuvenate after exploring{" "}
                    <b>Mugoya Estate</b> or <b>Akiba Estate</b>.
                </li>
                <li>
                    Adventurous and playful moments in bustling spots like{" "}
                    <b>South C Shopping Centre</b> or serene areas like{" "}
                    <b>Bellevue South C</b>.
                </li>
                <li>
                    Customized services designed to create unforgettable memories in South
                    C.
                </li>
            </ul>
            <p>
                No matter where you are in South C, our escorts provide a seamless blend
                of charm, professionalism, and comfort.
            </p>
            <h3>Availability and Rates</h3>
            <p>
                Our South C escorts are available 24/7, providing services tailored to
                your needs. With transparent pricing and exceptional service, you can
                confidently plan your day or evening in this vibrant neighborhood.
            </p>
            <h3>Contact South C Escorts Today</h3>
            <p>
                Discover the finest escort services South C has to offer. Browse through
                our directory to find your perfect match near <b>Nextgen Mall</b>,{" "}
                <b>Bellevue</b>, or <b>Mugoya Estate</b>. Whether you’re visiting{" "}
                <b>Akiba Estate</b>, <b>Red Cross Headquarters</b>, or exploring{" "}
                <b>Wilson Airport</b>, our escorts are here to make your moments
                unforgettable.
            </p>
            <p>
                Don’t wait – South C’s most charming escorts are just a call away. Book
                now to enjoy unmatched services and memorable experiences in one of
                Nairobi’s most vibrant neighborhoods.
            </p>
        </Location>
    );
};

export default SouthC;