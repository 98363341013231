import React from "react";
import "./LocationsSection.css";

const LocationsSection = () => {
    return (
        <div className="locations-section">
            <h2>Other Locations in Nairobi</h2>
            <ul className="locations-list">
                {[
                    { name: "Kilimani", slug: "kilimani-escorts" },
                    { name: "Westlands", slug: "westlands-escorts" },
                    { name: "Kileleshwa", slug: "kileleshwa-escorts" },
                    { name: "Donholm", slug: "donholm-escorts" },
                    { name: "Lavington", slug: "lavington-escorts" },
                    { name: "South B", slug: "south-b-escorts" },
                    { name: "South C", slug: "south-c-escorts" },
                    { name: "Langata", slug: "langata-escorts" },
                    { name: "Embakasi", slug: "embakasi-escorts" },
                    { name: "Ruaka", slug: "ruaka-escorts" },
                    { name: "Kasarani", slug: "kasarani-escorts" },
                    { name: "Ruiru", slug: "ruiru-escorts" },
                    { name: "Umoja", slug: "umoja-escorts" },
                    { name: "Utawala", slug: "utawala-escorts" },
                    { name: "Roysambu", slug: "roysambu-escorts" },
                    { name: "Madaraka", slug: "madaraka-escorts" },
                ].map((location) => (
                    <li key={location.name}>
                        <a
                            href={`/escorts/nairobi/${location.slug}`}
                            className="location-link"
                        >
                            {location.name} escorts
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LocationsSection;