import React from "react";
import { Form, Select, Checkbox } from "antd";

const { Option } = Select;

const Location = ({ formData, handleChange, towns, estates, countries }) => (
  <Form
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 14 }}
    layout="horizontal"
    style={{ maxWidth: 600, margin: '0 auto' }}
  >

    <Form.Item label="City/ Town/ County:">
      <Select
        name="city"
        value={formData.city}
        onChange={(value) => handleChange({ target: { name: "city", value } })}
      >
        {(towns[formData.country] || []).map((town) => (
          <Option key={town} value={town}>
            {town}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Estate:">
      <Select
        name="estate"
        value={formData.estate}
        onChange={(value) => handleChange({ target: { name: "estate", value } })}
      >
        {(estates[formData.city] || []).map((estate) => (
          <Option key={estate} value={estate}>
            {estate}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Incall:">
      <Checkbox
        name="incall"
        checked={formData.availability.incall}
        onChange={(e) => handleChange({ target: { name: "availability.incall", value: e.target.checked } })}
      />
    </Form.Item>
    <Form.Item label="Outcall:">
      <Checkbox
        name="outcall"
        checked={formData.availability.outcall}
        onChange={(e) => handleChange({ target: { name: "availability.outcall", value: e.target.checked } })}
      />
    </Form.Item>
  </Form>
);

export default Location;