import React from "react";
import Location from "./Location";
import { Helmet } from "react-helmet";

const Umoja = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Place",
        "name": "Umoja, Nairobi",
        "description": "Explore Umoja, a lively neighborhood in Nairobi's Eastlands, known for its vibrant markets, close-knit community, and key locations like Umoja Market, Bee Centre, and Manyanja Road.",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Umoja",
            "addressRegion": "Nairobi",
            "addressCountry": "KE"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": -1.2841, // Replace with Umoja's actual latitude
            "longitude": 36.8968 // Replace with Umoja's actual longitude
        },
        "hasPart": [
            {
                "@type": "Place",
                "name": "Umoja 1 Estate",
                "description": "A lively residential area with schools, shops, and public transport."
            },
            {
                "@type": "Place",
                "name": "Umoja 2 Estate",
                "description": "A quieter alternative with spacious housing options."
            },
            {
                "@type": "Place",
                "name": "Innercore",
                "description": "Known for its vibrant streets and local businesses."
            },
            {
                "@type": "Place",
                "name": "Kwa Miti Area",
                "description": "A bustling hub for shopping and socializing."
            },
            {
                "@type": "Place",
                "name": "Umoja Catholic Church",
                "description": "A spiritual and cultural landmark for the local community."
            }
        ]
    };

    return (
        <Location
            title="Discover Umoja | A Vibrant Community in Nairobi"
            description="Explore Umoja, a lively neighborhood in Nairobi's Eastlands. Learn about key locations like Umoja Market, Bee Centre, Manyanja Road, and Innercore."
            estate="umoja"
        >
            {/* Add Structured Data in Helmet */}
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>

            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Welcome to Umoja – Nairobi’s Vibrant Eastlands Neighborhood
            </h2>
            <p>
                Umoja is one of Nairobi’s most dynamic neighborhoods, located in the
                heart of Eastlands. Known for its lively markets, close-knit community,
                and convenient access to amenities, Umoja is the perfect place for
                families, professionals, and visitors looking for an authentic Nairobi
                experience.
            </p>
            <h3>Why Visit Umoja?</h3>
            <p>
                Umoja combines a rich cultural heritage with modern conveniences,
                offering something for everyone. From bustling markets to serene
                residential estates, Umoja provides a welcoming environment for all.
            </p>
            <ul>
                <li>
                    <b>Vibrant Markets:</b> Explore <b>Umoja Market</b> and nearby{" "}
                    <b>Mutindwa Market</b> for fresh produce, unique goods, and local
                    delicacies.
                </li>
                <li>
                    <b>Convenient Shopping:</b> Visit <b>Bee Centre</b> for a variety of
                    shops and eateries that cater to your everyday needs.
                </li>
                <li>
                    <b>Well-Connected Roads:</b> Umoja is easily accessible via{" "}
                    <b>Outer Ring Road</b> and <b>Manyanja Road</b>, making it a
                    convenient location for commuting.
                </li>
            </ul>
            <h3>Explore Umoja’s Key Locations</h3>
            <p>
                Umoja is home to several iconic spots that highlight its character and
                charm:
            </p>
            <ul>
                <li>
                    <b>Umoja 1 Estate:</b> A lively residential area with schools, shops,
                    and public transport.
                </li>
                <li>
                    <b>Umoja 2 Estate:</b> A quieter alternative with spacious housing
                    options.
                </li>
                <li>
                    <b>Innercore:</b> Known for its vibrant streets and local businesses.
                </li>
                <li>
                    <b>Kwa Miti Area:</b> A bustling hub for shopping and socializing.
                </li>
                <li>
                    <b>Umoja Catholic Church:</b> A spiritual and cultural landmark for
                    the local community.
                </li>
            </ul>
            <p>
                For families, professionals, or anyone seeking a community-oriented
                environment, Umoja offers a balance of convenience and vibrancy.
            </p>
            <h3>Activities in Umoja</h3>
            <p>
                Umoja is more than just a residential area – it’s a hub of activity.
                Whether you’re shopping at the local markets, enjoying a meal at one of
                the many eateries, or exploring the nearby <b>Mutindwa Market</b>,
                there’s always something to do.
            </p>
            <ul>
                <li>
                    Shop for fresh produce and unique items at <b>Umoja Market</b> and{" "}
                    <b>Mutindwa Market</b>.
                </li>
                <li>
                    Enjoy local dishes and street food along <b>Manyanja Road</b>.
                </li>
                <li>Relax and unwind in the quiet streets of <b>Umoja 2 Estate</b>.</li>
            </ul>
            <h3>Visit Umoja Today</h3>
            <p>
                Umoja is a vibrant neighborhood that welcomes everyone with open arms.
                Whether you’re looking for a new home, a place to shop, or a community
                to connect with, Umoja has it all. Come and explore this dynamic part of
                Nairobi and experience its unique charm!
            </p>
            <p>
                Umoja’s energy and warmth make it one of Nairobi’s most beloved
                neighborhoods. Don’t miss out – visit Umoja and see why it’s the heart
                of Eastlands.
            </p>
        </Location>
    );
};

export default Umoja;