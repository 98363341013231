import React from "react";
import Location from "./Location";
import { Helmet } from "react-helmet";

const Langata = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Langata Escorts",
        "description":
            "Discover premium escort services in Langata. Connect with professionals near Galleria Mall, Freedom Heights, Carnivore Grounds, Nyayo Estate, and Bomas of Kenya for unforgettable experiences.",
        "provider": {
            "@type": "Organization",
            "name": "Kenya Hookups",
            "url": "https://www.kenyahookups.come/scorts/nairobi/langata-escorts"
        },
        "areaServed": {
            "@type": "Place",
            "name": "Langata",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "Langata",
                "addressRegion": "Nairobi",
                "addressCountry": "KE"
            }
        },
        "hasPart": [
            {
                "@type": "Place",
                "name": "Galleria Mall",
                "description": "A modern shopping center with retail stores, restaurants, and entertainment spots."
            },
            {
                "@type": "Place",
                "name": "Freedom Heights",
                "description": "A luxury residential and commercial area in Langata."
            },
            {
                "@type": "Place",
                "name": "Carnivore Grounds",
                "description": "A popular entertainment and dining venue famous for events and nightlife."
            },
            {
                "@type": "Place",
                "name": "Nyayo Estate Langata",
                "description": "A well-organized residential estate offering a quiet and comfortable living environment."
            },
            {
                "@type": "Place",
                "name": "Bomas of Kenya",
                "description": "A cultural center showcasing Kenya’s diverse ethnic heritage through performances and exhibits."
            },
            {
                "@type": "Place",
                "name": "Wilson Airport",
                "description": "A busy domestic airport providing quick access to regional destinations."
            },
            {
                "@type": "Place",
                "name": "Langata Hospital",
                "description": "A reputable healthcare facility providing medical services to residents and visitors."
            },
            {
                "@type": "Place",
                "name": "Uhuru Gardens",
                "description": "A national monument and park, ideal for relaxation and historical exploration."
            }
        ],
        "offers": {
            "@type": "Offer",
            "url": "https://www.kenyahookups.come/scorts/nairobi/langata-escorts",
            "availability": "https://schema.org/InStock",
            "priceCurrency": "KES",
            "price": "Negotiable",
            "validFrom": new Date().toISOString()
        }
    };

    return (
        <Location
            title="Langata Escorts | Exclusive Services in Nairobi’s Iconic Suburb"
            description="Discover the best escorts in Langata. Connect with professionals near Galleria Mall, Freedom Heights, Carnivore Grounds, Nyayo Estate, and Bomas of Kenya for unforgettable experiences."
            estate="langata"
        >
            {/* Add Structured Data in Helmet */}
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>

            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Langata Escorts – Unique Experiences in Nairobi’s Historic Suburb
            </h2>
            <p>
                Welcome to Langata, a neighborhood steeped in history and culture,
                offering a vibrant mix of residential charm and recreational landmarks.
                From the bustling <b>Galleria Mall</b> to the serene <b>Freedom
                    Heights</b>, Langata is the perfect destination for unforgettable
                moments. Whether you're near <b>Carnivore Grounds</b>, <b>Nyayo
                    Estate</b>, or <b>Bomas of Kenya</b>, our Langata escorts are here to
                provide exceptional companionship.
            </p>
            <h3>Why Choose Langata Escorts?</h3>
            <p>
                Langata is a unique blend of modern amenities and cultural heritage,
                making it ideal for discreet and exciting encounters. From the vibrant{" "}
                <b>Dam Estate</b> to the lively <b>Southlands Estate</b>, our escorts
                cater to a wide range of preferences, ensuring a memorable experience.
            </p>
            <ul>
                <li>
                    <b>Prime Locations:</b> Services near <b>Freedom Heights</b>,{" "}
                    <b>Wilson Airport</b>, and <b>Langata Hospital</b>.
                </li>
                <li>
                    <b>Relaxed Environment:</b> Enjoy private and serene moments in areas
                    like <b>Nyayo Estate</b> and <b>Dam Estate</b>.
                </li>
                <li>
                    <b>Personalized Experiences:</b> Tailored services that match your
                    preferences and schedule.
                </li>
            </ul>
            <h3>Exclusive Services in Langata</h3>
            <p>
                Our Langata escorts specialize in offering a variety of services to
                complement the area’s vibrant energy:
            </p>
            <ul>
                <li>
                    Elegant companionship for evenings at <b>Carnivore Grounds</b> or
                    casual meetups at <b>Freedom Heights</b>.
                </li>
                <li>
                    Relaxing full-body massages to rejuvenate after a day at{" "}
                    <b>Bomas of Kenya</b> or <b>Uhuru Gardens</b>.
                </li>
                <li>
                    Adventurous and playful encounters in residential areas like{" "}
                    <b>Southlands Estate</b> or <b>Ngei Estate</b>.
                </li>
                <li>
                    Customized experiences in serene neighborhoods such as{" "}
                    <b>Nyayo Estate</b> and <b>Dam Estate</b>.
                </li>
            </ul>
            <h3>Availability and Rates</h3>
            <p>
                Our Langata escorts are available 24/7, offering services designed to
                suit your needs. With competitive rates and exceptional service, you can
                confidently plan your time in Langata.
            </p>
            <h3>Contact Langata Escorts Today</h3>
            <p>
                Experience the finest escort services Langata has to offer. Browse
                through our directory to find your perfect match near{" "}
                <b>Freedom Heights</b>, <b>Galleria Mall</b>, or <b>Carnivore
                    Grounds</b>. Whether you’re in <b>Wilson Airport</b>, <b>Bomas of
                        Kenya</b>, or <b>Southlands Estate</b>, our escorts are here to make
                your moments extraordinary.
            </p>
        </Location>
    );
};

export default Langata;