import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography, Box, Chip, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

import { SITE_URL } from '../constants';

const ProfileCard = ({ name, estates, city, about, age, image, phone }) => {
  const [img, setImg] = useState("");
  const profilePageUrl = `${SITE_URL}escort/${name}`;
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);
  const capitalizedTitle = capitalizeFirstLetter(name);

  const altText = `Picture of ${capitalizedTitle}, a Kenyan escort in ${city}, located in ${estates}`;
  const description = `Meet ${name}, a ${age} year old from ${estates}, ${city}`;

  useEffect(() => {
    if (image[0]?.image_url?.startsWith('http')) {
      setImg(image[0].image_url);
    } else {
      setImg("https://media-v2.tryst.a4cdn.org/profiles/f41dee16-c477-4090-96f9-961ac4d36428/photos/2867331b-2d9a-4341-855b-813b8f36e119/small.avif");
    }
  }, [image]);

  return (
    <Link to={profilePageUrl} style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          backgroundColor: "#F4F6F8",
          maxWidth: "400px",
          margin: "10px auto",
          boxShadow: 3,
          height: "245px",
          transition: "transform 0.3s",
          fontFamily: "'Inter', sans-serif",
          "&:hover": {
            transform: "scale(1.02)",
          },
        }}
      >
        {/* Image Section */}
        <CardMedia
          component="img"
          alt={altText}
          image={img}
          sx={{
            width: "160px",
            height: "100%",
            objectFit: "cover",
            margin: 0,
            padding: 0,
          }}
        />

        {/* Content Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'space-between',
            width: "205px",
            padding: "4px",
          }}
        >
          <CardContent
            sx={{
              paddingLeft: "8px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              height: "100%", // Ensure the content spans the full height
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between", // Push items to opposite ends
                alignItems: "center", // Vertically align content
                width: "100%", // Ensure it spans the entire row
              }}
            >
              {/* Title */}
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "700",
                  color: "#1A1A4A",
                  fontSize: "20px",
                }}
              >
                {capitalizedTitle}, {age}
              </Typography>

              {/* Escort Tag */}
              <Chip
                label="VIP"
                sx={{
                  backgroundColor: "#e74c3c",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "10px",
                  height: "20px",
                  width: "50px", // Fixed width
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              />
            </Box>

            {/* Location with Icon */}
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: "#646464",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginBottom: "auto", // Push description to the bottom
              }}
            >
              <HomeIcon fontSize="small" sx={{ color: "#646464" }} />
              {estates}, {city}
            </Typography>

            {/* Description */}
            <Typography
              variant="body2"
              color="text.primary"
              sx={{
                marginTop: "auto", // Ensure it stays at the bottom
                color: "#4A4A4A",
                lineHeight: "1.4",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3, // Limit to 2 lines
              }}
            >
              {about}
            </Typography>

          </CardContent>
        </Box>
      </Card>
    </Link>
  );
};

export default ProfileCard;