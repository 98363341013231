import React from "react";
import { Form, Checkbox, Row, Col } from "antd";
import './Services.css';
const servicesCategories = {
  "Sexual Services": [
    "Vaginal sex",
    "Blowjob (CD)",
    "Raw Blowjob",
    "Handjob",
    "Threesome",
    "69",
    "Anal sex",
    "Anal Rimming",
    "French Kissing",
    "Lesbian show",
    "Cum on body",
    "Cum on face",
    "Cum in mouth",
    "Cum Swallow",
    "Squirting",
    "Couple sex",
    "Group sex",
  ],
  "Fetish and Kinks": [
    "Foot fetish",
    "Domination",
    "BDSM",
    "Strapon dildo",
    "Golden shower",
    "Pegging"
  ],
  "Additional Services": [
    "Massage",
    "Lapdance",
    "Dinner dates",
    "Sleep over",
    "Video call",
    "Sex toys"
  ]
};

const Services = ({ formData, handleChange }) => (
  <Form layout="vertical" className="section" style={{ maxWidth: 800, margin: '0 auto' }}>
    {Object.keys(servicesCategories).map((category, catIndex) => (
      <div key={catIndex} className="service-category">
        <h3>{category}</h3>
        <Checkbox.Group style={{ width: '100%' }}>
          <Row>
            {servicesCategories[category].map((service, index) => (
              <Col span={12} key={index} className="checkbox-wrapper">
                <Checkbox
                  name={`services.${service}`}
                  checked={formData.services && formData.services[service]}
                  onChange={(e) =>
                    handleChange({
                      target: { name: `services.${service}`, value: e.target.checked },
                    })
                  }
                >
                  {service}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
    ))}
  </Form>
);

export default Services;