import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import { SITE_URL } from '../constants';
import './ListingCard.css';

const { Meta } = Card;

const ListingCard = ({ name, estates, city, phone, age, about, image, id }) => {
  const [img, setImg] = useState("");
  const profilepageurl = SITE_URL + "escort/" + name;
  const handleCall = () => {
    window.location.href = `tel:${phone}`;
  };
  let description = `Meet ${name}, a ${age} year old from ${estates}, ${city}`;
  const capitalizeFirstLetter = (string) => { return string.charAt(0).toUpperCase() + string.slice(1); };
  const capitalizedTitle = capitalizeFirstLetter(name);

  useEffect(() => {
    if (image[0].image_url.startsWith('http')) {
      setImg(image[0].image_url);
    }
    else {
      setImg("https://media-v2.tryst.a4cdn.org/profiles/f41dee16-c477-4090-96f9-961ac4d36428/photos/2867331b-2d9a-4341-855b-813b8f36e119/small.avif");
    }
  }, [image]);

  return (
    <Link to={profilepageurl} className="card-link">
      <Card
        cover={
          <div className="card-image-container">
            <img alt="example" className="card-image" src={img} loading="lazy" />
          </div>
        }
      >
        <Meta
          title={<span className="card-title">{capitalizedTitle}</span>}
          description={<span className="card-description">{description}</span>}
        />
      </Card>
    </Link>
  );
};

export default ListingCard;