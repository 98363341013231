import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';
import "./NavBar.css";

const NavBar = () => {

  const [showMenu, setShowMenu] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };


  return (
    <header className="header">

      {isMobile && <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}> {showMenu ? <IoClose size={30} /> : <IoMenu size={30} />} </div>}
      <img src="/logo192.png" alt="" className="logo" ></img>
      <NavLink to="/" className="nav__logo"> Kenyan Hookups </NavLink>
      <div className={`nav__menu ${showMenu ? "show-menu" : ""}`} id="nav-menu">
        
        <ul className="auth__list">
          <li className="nav__item"> <NavLink to="/" className="nav__link" onClick={closeMenuOnMobile}> ESCORTS </NavLink> </li>
          <li className="nav__item"> <NavLink to="/blog" className="nav__link" onClick={closeMenuOnMobile}> OUR BLOG </NavLink> </li>
          <li className="nav__item"> <NavLink to="/contactus" className="nav__link" onClick={closeMenuOnMobile}> CONTACT US </NavLink> </li>
          {/* <li className="nav__item"> <NavLink to="/login" className="nav__link nav__cta" onClick={closeMenuOnMobile}> POST AD </NavLink></li> */}
          {/* <li className="nav__item"> <NavLink to="/registration" className="nav__link nav__cta"> POST AD </NavLink></li>*/}
        </ul>
      </div>

    </header>
  );
};

export default NavBar;