import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../constants';
import LocationsSection from '../shared/LocationsSection';
import { Helmet } from 'react-helmet';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}blogs?page=${currentPage}&limit=5`);
      const blogs = response.data.blogs || response.data; // Adjust if `blogs` is not a key
      const total = response.data.totalPages || 1; // Adjust if `totalPages` is named differently
      const current = response.data.currentPage || currentPage;

      setBlogPosts(
        blogs.map((post) => ({
          id: post.id,
          title: post.title,
          author: post.author_name,
          date: post.published_date,
          content: post.content.substring(0, 150) + '...',
          image_url: post.image_url,
          slug: post.slug,
        }))
      );
      setTotalPages(total);
      setCurrentPage(current);
    } catch (err) {
      console.error("Error fetching blogs:", err); // Log the error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [currentPage]);

  if (loading) return <p>Loading blogs...</p>;
  if (error) return <p>Error loading blogs: {error.message}</p>;
  if (blogPosts.length === 0) return <p>No blogs available at the moment.</p>;

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Kenya Hookups Blog: Insights on Escort Services in Westlands & Beyond</title>
        <meta name="description" content="Discover the latest tips, guides, and insights about escort services in Westlands and beyond on the Kenya Hookups Blog." />
        <meta name="keywords" content="Kenya Hookups, escort services, Westlands, Nairobi, blog, insights" />
        <meta property="og:title" content="Kenya Hookups Blog: Insights on Escort Services in Westlands & Beyond" />
        <meta property="og:description" content="Explore expert tips and guides on escort services in Westlands and Nairobi." />
        <meta property="og:type" content="website" />
      </Helmet>

      <header style={styles.header}>
        <div style={styles.headerOverlay}>
          <h1 style={styles.title}>Kenya Hookups Blog</h1>
          <p style={styles.subtitle}>Explore the latest tips, guides, and insights about escort services in Westlands and beyond.</p>
        </div>
      </header>

      <main>
        {blogPosts.map((post) => (
          <article key={post.id} style={styles.post}>
            {post.image_url && <img src={post.image_url} alt={post.title} style={styles.image} />}
            <Link to={`/blogs/${post.slug}`} style={styles.link}>
              <h2>{post.title}</h2>
            </Link>
            <p style={styles.meta}>
              By {post.author} on {new Date(post.date).toLocaleDateString()}
            </p>
            <p>{post.content}</p>
          </article>
        ))}
        <div style={styles.pagination}>
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
          <p style={styles.paginationInfo}>
            Page {currentPage} of {totalPages}
          </p>
        </div>
      </main>
      <LocationsSection />
    </div>
  );
};

const styles = {
  container: { fontFamily: 'Arial, sans-serif', margin: 0, padding: 0, backgroundColor: '#f4f4f4', lineHeight: '1.6' },
  header: { color: '#333', padding: '10px 0', textAlign: 'center' },
  title: { color: '#e74c3c' },
  post: { backgroundColor: '#fff', margin: '20px', padding: '20px', borderRadius: '5px', maxWidth: '800px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' },
  meta: { fontSize: '0.9em', color: '#555' },
  link: { textDecoration: 'none', color: '#333' },
  image: { maxWidth: '100%', borderRadius: '5px', marginBottom: '15px' },
  pagination: { textAlign: 'center', margin: '20px' },
  paginationInfo: { marginTop: '10px', fontSize: '1em', color: '#333', textAlign: 'center' },
};

export default Blog;
