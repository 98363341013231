// Sidebar.js
import React from 'react';
import { Menu } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Estates from './Estates';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const navigate = useNavigate();

  const handleClick = async (key) => {
    navigate(`/escorts/nairobi/${key}`);
  };

  return (
    <>
      {isMobile ? (
        <div className='menu-grid'>
          <Estates handleClick={handleClick} />
        </div>
      ) : (
        <div className='desktop-sidebar'>
          <Estates handleClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default Sidebar;