import React from "react";

const DetailsNew = ({ gender, age, nationality, sexual_orientation, city, estates, availability_incall, availability_outcall }) => {
  const styles = {
    container: {
      display: "flex",
      flexWrap: "wrap", // Allows sections to wrap
      gap: "8px", // Space between sections
      justifyContent: "left", // Align sections to the left
      borderRadius: "8px",
      margin: "16px",
      padding: "16px",
      boxSizing: "border-box",
      backgroundColor: "#F8FAFC",
    },
    card: {
      width: "300px",
      fontFamily: "Arial, sans-serif",
      boxSizing: "border-box",
    },
    section: {
      padding: "16px",
      marginBottom: "4px",
      boxSizing: "border-box",
    },
    title: {
      fontWeight: "700",
      marginBottom: "4px",
      fontSize: "16px",
      color: "#111",
      textAlign: "left", // Align text to the left
    },
    value: {
      fontWeight: "400",
      fontSize: "16px",
      textAlign: "left", // Align text to the left
      color: "#212a33",
    },
    similarProfilesContainer: {
      marginTop: "16px",
      padding: "16px",
      backgroundColor: "#FFF",
      borderRadius: "8px",
      overflowX: "auto", // Enables horizontal scrolling
      whiteSpace: "nowrap", // Prevents wrapping
    },
    profileCard: {
      display: "inline-block", // Keeps items in a row
      width: "200px",
      height: "250px",
      margin: "8px",
      backgroundColor: "#ddd",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      lineHeight: "250px", // Centers text vertically (for dummy divs)
    },
  };

  const availability = availability_incall && availability_outcall ? "Incall & Outcall"
    : availability_incall ? "Incall"
      : availability_outcall ? "Outcall"
        : "None";

  return (
    <div style={styles.container}>

      {/* Section: Based in */}
      <div style={styles.section}>
        <div style={styles.title}>Based in</div>
        <div style={styles.value}>{estates}</div>
      </div>

      {/* Section: Gender */}
      <div style={styles.section}>
        <div style={styles.title}>Gender</div>
        <div style={styles.value}>♀ {gender} (She)</div>
      </div>

      {/* Section: Age */}
      <div style={styles.section}>
        <div style={styles.title}>Age</div>
        <div style={styles.value}>{age}</div>
      </div>


      {/* Section: Caters to */}
      <div style={styles.section}>
        <div style={styles.title}>Orientation</div>
        <div style={styles.value}>{sexual_orientation}</div>
      </div>

      {/* Section: Ethnicity */}
      <div style={styles.section}>
        <div style={styles.title}>Ethnicity</div>
        <div style={styles.value}>{nationality}</div>
      </div>

      {/* Section: Body Type */}
      <div style={styles.section}>
        <div style={styles.title}>Body Type</div>
        <div style={styles.value}>Petite</div>
      </div>

      {/* Section: Availability */}
      <div style={styles.section}>
        <div style={styles.title}>Availability</div>
        <div style={styles.value}>{availability}</div>
      </div>

      {/* Section: Nationality */}
      <div style={styles.section}>
        <div style={styles.title}>Nationality</div>
        <div style={styles.value}>Kenyan</div>
      </div>

      {/* Section: Skin Colour */}
      <div style={styles.section}>
        <div style={styles.title}>Skin Colour</div>
        <div style={styles.value}>Lightskin</div>
      </div>


    </div>
  );
};

export default DetailsNew;