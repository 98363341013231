import React from "react";
import { Form, Select, InputNumber } from "antd";

const { Option } = Select;

const AvailabilityAndRates = ({ formData, handleChange }) => {
  const durations = ["30 minutes", "1 hour", "2 hours", "3 hours", "6 hours", "12 hours", "24 hours"];
  
  const handleRateChange = (duration, type, value) => {
    const durationKey = duration.replace(" ", "").toLowerCase();
    handleChange({ 
      target: { 
        name: `rates.${type}.${durationKey}`, 
        value 
      } 
    });
  };

  return (
    <Form
      layout="vertical"
      className="section"
      style={{ maxWidth: 700, margin: '0 auto' }}
    >
      <Form.Item label="Currency:" style={{ marginBottom: "35px" }}>
        <Select
          name="currency"
          value={formData.currency}
          onChange={(value) => handleChange({ target: { name: "currency", value } })}
        >
          <Option value="KES">KES - Kenya Shilling</Option>
          {/* Add other currencies as needed */}
        </Select>
      </Form.Item>
      <div className="rates-container">
        <div className="rate-header"></div>
        {durations.map((duration) => {
          const durationKey = duration.replace(" ", "").toLowerCase();
          return (
            <div key={duration} className="rate-row" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <div className="rate-duration" style={{ flex: '1' }}>{duration}</div>
              <Form.Item style={{ flex: '2', margin: "auto" }}>
                <InputNumber
                  className="rate-input"
                  type="number"
                  value={formData.rates.incall?.[durationKey] || ""}
                  onChange={(value) => handleRateChange(duration, 'incall', value)}
                  placeholder="Incall"
                />
              </Form.Item>
              <Form.Item style={{ flex: '2', margin: "5px" }}>
                <InputNumber
                  className="rate-input"
                  type="number"
                  value={formData.rates.outcall?.[durationKey] || ""}
                  onChange={(value) => handleRateChange(duration, 'outcall', value)}
                  placeholder="Outcall"
                />
              </Form.Item>
            </div>
          );
        })}
      </div>
    </Form>
  );
};

export default AvailabilityAndRates;